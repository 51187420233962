var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
export var HeaderResendSignUpConfirmationEmailDocument = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation HeaderResendSignUpConfirmationEmail {\n  resendSignUpConfirmationEmail(input: {}) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation HeaderResendSignUpConfirmationEmail {\n  resendSignUpConfirmationEmail(input: {}) {\n    clientMutationId\n  }\n}\n    "])));
export var SendSuggestionDocument = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation sendSuggestion($input: SendSuggestionInput!) {\n  sendSuggestion(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation sendSuggestion($input: SendSuggestionInput!) {\n  sendSuggestion(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var AuthNotPremiumBillingsErrorCreateCustomerPortalSessionDocument = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation AuthNotPremiumBillingsErrorCreateCustomerPortalSession($input: CreateCustomerPortalSessionInput!) {\n  session: createCustomerPortalSession(input: $input) {\n    url\n  }\n}\n    "], ["\n    mutation AuthNotPremiumBillingsErrorCreateCustomerPortalSession($input: CreateCustomerPortalSessionInput!) {\n  session: createCustomerPortalSession(input: $input) {\n    url\n  }\n}\n    "])));
export var AuthPremiumBillingNewCreateCheckoutSessionDocument = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation AuthPremiumBillingNewCreateCheckoutSession {\n  session: createCheckoutSession(input: {}) {\n    url\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthPremiumBillingNewCreateCheckoutSession {\n  session: createCheckoutSession(input: {}) {\n    url\n    clientMutationId\n  }\n}\n    "])));
export var AuthPremiumArticlesShowReachedTrialLimitPlayerUpgradeDocument = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    mutation AuthPremiumArticlesShowReachedTrialLimitPlayerUpgrade {\n  upgrade(input: {}) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthPremiumArticlesShowReachedTrialLimitPlayerUpgrade {\n  upgrade(input: {}) {\n    clientMutationId\n  }\n}\n    "])));
export var CreatePlaylistDocument = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    mutation createPlaylist($input: CreatePlaylistInput!) {\n  createPlaylist(input: $input) {\n    clientMutationId\n    playlist {\n      id\n      title\n    }\n  }\n}\n    "], ["\n    mutation createPlaylist($input: CreatePlaylistInput!) {\n  createPlaylist(input: $input) {\n    clientMutationId\n    playlist {\n      id\n      title\n    }\n  }\n}\n    "])));
export var AddArticleToPlaylistDocument = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    mutation addArticleToPlaylist($input: AddArticleToPlaylistInput!) {\n  addArticleToPlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation addArticleToPlaylist($input: AddArticleToPlaylistInput!) {\n  addArticleToPlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var RemoveArticleFromPlaylistDocument = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    mutation removeArticleFromPlaylist($input: RemoveArticleFromPlaylistInput!) {\n  removeArticleFromPlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation removeArticleFromPlaylist($input: RemoveArticleFromPlaylistInput!) {\n  removeArticleFromPlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var UpdatePlaylistDocument = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    mutation updatePlaylist($input: UpdatePlaylistInput!) {\n  updatePlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation updatePlaylist($input: UpdatePlaylistInput!) {\n  updatePlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var DeletePlaylistDocument = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    mutation deletePlaylist($input: DeletePlaylistInput!) {\n  deletePlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation deletePlaylist($input: DeletePlaylistInput!) {\n  deletePlaylist(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var AuthPremiumBillingCancelCancelDocument = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    mutation AuthPremiumBillingCancelCancel($input: CancelStripeInput!) {\n  cancelStripe(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthPremiumBillingCancelCancel($input: CancelStripeInput!) {\n  cancelStripe(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var AuthPremiumBillingTrialUpgradeDocument = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    mutation AuthPremiumBillingTrialUpgrade {\n  upgrade(input: {}) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthPremiumBillingTrialUpgrade {\n  upgrade(input: {}) {\n    clientMutationId\n  }\n}\n    "])));
export var AuthPremiumHomeHomePostModalMarkPostModalCheckedDocument = gql(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    mutation AuthPremiumHomeHomePostModalMarkPostModalChecked($input: MarkPostModalCheckedInput!) {\n  response: markPostModalChecked(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthPremiumHomeHomePostModalMarkPostModalChecked($input: MarkPostModalCheckedInput!) {\n  response: markPostModalChecked(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var AuthPremiumPracticesEditUpdatePracticeDocument = gql(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    mutation authPremiumPracticesEditUpdatePractice($input: UpdatePracticeInput!) {\n  result: updatePractice(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation authPremiumPracticesEditUpdatePractice($input: UpdatePracticeInput!) {\n  result: updatePractice(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var AuthPremiumPracticesDeletePracticeDocument = gql(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    mutation authPremiumPracticesDeletePractice($input: DeletePracticeInput!) {\n  result: deletePractice(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation authPremiumPracticesDeletePractice($input: DeletePracticeInput!) {\n  result: deletePractice(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var AuthPremiumPracticesNewCreatePracticeDocument = gql(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    mutation authPremiumPracticesNewCreatePractice($input: CreatePracticeInput!) {\n  result: createPractice(input: $input) {\n    practiceId\n    clientMutationId\n  }\n}\n    "], ["\n    mutation authPremiumPracticesNewCreatePractice($input: CreatePracticeInput!) {\n  result: createPractice(input: $input) {\n    practiceId\n    clientMutationId\n  }\n}\n    "])));
export var AuthPremiumPracticesShowPracticeDocument = gql(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    query authPremiumPracticesShowPractice($id: ID!) {\n  practice(id: $id) {\n    id\n    date\n    comment\n    placeName\n    feedbackComment\n    feedbackKeywords\n    feedbackCreatedAt\n  }\n}\n    "], ["\n    query authPremiumPracticesShowPractice($id: ID!) {\n  practice(id: $id) {\n    id\n    date\n    comment\n    placeName\n    feedbackComment\n    feedbackKeywords\n    feedbackCreatedAt\n  }\n}\n    "])));
export var AuthPremiumUserAttributesEditUpdateAttributesDocument = gql(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    mutation AuthPremiumUserAttributesEditUpdateAttributes($input: UpdateAttributesInput!) {\n  updateAttributes(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthPremiumUserAttributesEditUpdateAttributes($input: UpdateAttributesInput!) {\n  updateAttributes(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var AuthResignNewResignDocument = gql(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    mutation AuthResignNewResign {\n  resign(input: {}) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthResignNewResign {\n  resign(input: {}) {\n    clientMutationId\n  }\n}\n    "])));
export var AuthCancelNewCancelDocument = gql(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n    mutation AuthCancelNewCancel($input: CancelInput!) {\n  cancel(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthCancelNewCancel($input: CancelInput!) {\n  cancel(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var AuthUsersEditUpdateUserDocument = gql(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n    mutation AuthUsersEditUpdateUser($input: UpdateUserInput!) {\n  updateUser(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthUsersEditUpdateUser($input: UpdateUserInput!) {\n  updateUser(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var AuthUsersShowResendEmailUpdateConfirmationEmailDocument = gql(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n    mutation AuthUsersShowResendEmailUpdateConfirmationEmail {\n  resendEmailUpdateConfirmationEmail(input: {}) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthUsersShowResendEmailUpdateConfirmationEmail {\n  resendEmailUpdateConfirmationEmail(input: {}) {\n    clientMutationId\n  }\n}\n    "])));
export var AuthUsersShowCreateCustomerPortalSessionDocument = gql(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n    mutation AuthUsersShowCreateCustomerPortalSession {\n  session: createCustomerPortalSession(input: {}) {\n    url\n    clientMutationId\n  }\n}\n    "], ["\n    mutation AuthUsersShowCreateCustomerPortalSession {\n  session: createCustomerPortalSession(input: {}) {\n    url\n    clientMutationId\n  }\n}\n    "])));
export var ContactsNewSendContactDocument = gql(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n    mutation ContactsNewSendContact($input: SendContactInput!) {\n  sendContact(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation ContactsNewSendContact($input: SendContactInput!) {\n  sendContact(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var UnAuthConfirmationNewResendSignUpConfirmationEmailDocument = gql(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n    mutation UnAuthConfirmationNewResendSignUpConfirmationEmail($email: String!) {\n  resendSignUpConfirmationEmail(input: {email: $email}) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation UnAuthConfirmationNewResendSignUpConfirmationEmail($email: String!) {\n  resendSignUpConfirmationEmail(input: {email: $email}) {\n    clientMutationId\n  }\n}\n    "])));
export var ExecutePasswordResetDocument = gql(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n    mutation executePasswordReset($input: ExecutePasswordResetInput!) {\n  executePasswordReset(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation executePasswordReset($input: ExecutePasswordResetInput!) {\n  executePasswordReset(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var StartPasswordResetDocument = gql(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n    mutation startPasswordReset($input: StartPasswordResetInput!) {\n  startPasswordReset(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation startPasswordReset($input: StartPasswordResetInput!) {\n  startPasswordReset(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var SignInDocument = gql(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n    mutation signIn($input: SignInInput!) {\n  signIn(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation signIn($input: SignInInput!) {\n  signIn(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export var ExampleQueryDocument = gql(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n    mutation ExampleQuery($input: SendSuggestionInput!) {\n  sendSuggestion(input: $input) {\n    clientMutationId\n  }\n}\n    "], ["\n    mutation ExampleQuery($input: SendSuggestionInput!) {\n  sendSuggestion(input: $input) {\n    clientMutationId\n  }\n}\n    "])));
export function getSdk(requester) {
    return {
        HeaderResendSignUpConfirmationEmail: function (variables, options) {
            return requester(HeaderResendSignUpConfirmationEmailDocument, variables, options);
        },
        sendSuggestion: function (variables, options) {
            return requester(SendSuggestionDocument, variables, options);
        },
        AuthNotPremiumBillingsErrorCreateCustomerPortalSession: function (variables, options) {
            return requester(AuthNotPremiumBillingsErrorCreateCustomerPortalSessionDocument, variables, options);
        },
        AuthPremiumBillingNewCreateCheckoutSession: function (variables, options) {
            return requester(AuthPremiumBillingNewCreateCheckoutSessionDocument, variables, options);
        },
        AuthPremiumArticlesShowReachedTrialLimitPlayerUpgrade: function (variables, options) {
            return requester(AuthPremiumArticlesShowReachedTrialLimitPlayerUpgradeDocument, variables, options);
        },
        createPlaylist: function (variables, options) {
            return requester(CreatePlaylistDocument, variables, options);
        },
        addArticleToPlaylist: function (variables, options) {
            return requester(AddArticleToPlaylistDocument, variables, options);
        },
        removeArticleFromPlaylist: function (variables, options) {
            return requester(RemoveArticleFromPlaylistDocument, variables, options);
        },
        updatePlaylist: function (variables, options) {
            return requester(UpdatePlaylistDocument, variables, options);
        },
        deletePlaylist: function (variables, options) {
            return requester(DeletePlaylistDocument, variables, options);
        },
        AuthPremiumBillingCancelCancel: function (variables, options) {
            return requester(AuthPremiumBillingCancelCancelDocument, variables, options);
        },
        AuthPremiumBillingTrialUpgrade: function (variables, options) {
            return requester(AuthPremiumBillingTrialUpgradeDocument, variables, options);
        },
        AuthPremiumHomeHomePostModalMarkPostModalChecked: function (variables, options) {
            return requester(AuthPremiumHomeHomePostModalMarkPostModalCheckedDocument, variables, options);
        },
        authPremiumPracticesEditUpdatePractice: function (variables, options) {
            return requester(AuthPremiumPracticesEditUpdatePracticeDocument, variables, options);
        },
        authPremiumPracticesDeletePractice: function (variables, options) {
            return requester(AuthPremiumPracticesDeletePracticeDocument, variables, options);
        },
        authPremiumPracticesNewCreatePractice: function (variables, options) {
            return requester(AuthPremiumPracticesNewCreatePracticeDocument, variables, options);
        },
        authPremiumPracticesShowPractice: function (variables, options) {
            return requester(AuthPremiumPracticesShowPracticeDocument, variables, options);
        },
        AuthPremiumUserAttributesEditUpdateAttributes: function (variables, options) {
            return requester(AuthPremiumUserAttributesEditUpdateAttributesDocument, variables, options);
        },
        AuthResignNewResign: function (variables, options) {
            return requester(AuthResignNewResignDocument, variables, options);
        },
        AuthCancelNewCancel: function (variables, options) {
            return requester(AuthCancelNewCancelDocument, variables, options);
        },
        AuthUsersEditUpdateUser: function (variables, options) {
            return requester(AuthUsersEditUpdateUserDocument, variables, options);
        },
        AuthUsersShowResendEmailUpdateConfirmationEmail: function (variables, options) {
            return requester(AuthUsersShowResendEmailUpdateConfirmationEmailDocument, variables, options);
        },
        AuthUsersShowCreateCustomerPortalSession: function (variables, options) {
            return requester(AuthUsersShowCreateCustomerPortalSessionDocument, variables, options);
        },
        ContactsNewSendContact: function (variables, options) {
            return requester(ContactsNewSendContactDocument, variables, options);
        },
        UnAuthConfirmationNewResendSignUpConfirmationEmail: function (variables, options) {
            return requester(UnAuthConfirmationNewResendSignUpConfirmationEmailDocument, variables, options);
        },
        executePasswordReset: function (variables, options) {
            return requester(ExecutePasswordResetDocument, variables, options);
        },
        startPasswordReset: function (variables, options) {
            return requester(StartPasswordResetDocument, variables, options);
        },
        signIn: function (variables, options) {
            return requester(SignInDocument, variables, options);
        },
        ExampleQuery: function (variables, options) {
            return requester(ExampleQueryDocument, variables, options);
        }
    };
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29;
