import { useState, useMemo, useCallback } from 'react';
import { propsSchema } from './props';
var useHook = function (props) {
    var _a = propsSchema.parse(props), today = _a.today, dayCount = _a.dayCount, startDate = _a.startDate, endDate = _a.endDate, practices = _a.practices, beginningOfMonth = _a.beginningOfMonth, articleUserComments = _a.articleUserComments, articleUserCompletes = _a.articleUserCompletes, articleUserHistories = _a.articleUserHistories;
    var _b = useState(null), selectedDay = _b[0], setSelectedDay = _b[1];
    var days = Array.from({ length: dayCount }, function (_, i) {
        var date = startDate.add(i, 'day');
        var isToday = date.isSame(today, 'day');
        var events = [];
        var tempPractices = practices.filter(function (practice) {
            return practice.date.isSame(date, 'day');
        });
        events.push.apply(events, tempPractices.map(function (practice) {
            var title = "\u7DF4\u7FD2";
            if (practice.placeName) {
                title += "@".concat(practice.placeName);
            }
            return {
                id: "practice-".concat(practice.id),
                title: title,
                type: 'practice',
                href: "/practices/".concat(practice.id),
            };
        }));
        var tempHistories = articleUserHistories.filter(function (history) {
            return history.viewedAt.isSame(date, 'day');
        });
        if (tempHistories.length > 0) {
            events.push({
                id: 'movie',
                type: 'movie',
                title: "\u52D5\u753B\u8996\u8074 ".concat(tempHistories.length, "\u4EF6"),
            });
        }
        var tempComments = articleUserComments.filter(function (comment) {
            return comment.updatedAt.isSame(date, 'day');
        });
        if (tempComments.length > 0) {
            events.push({
                id: 'comment',
                type: 'comment',
                title: "\u52D5\u753B\u30E1\u30E2 ".concat(tempComments.length, "\u4EF6"),
            });
        }
        var tempCompletes = articleUserCompletes.filter(function (complete) {
            return complete.createdAt.isSame(date, 'day');
        });
        if (tempCompletes.length > 0) {
            events.push({
                id: 'complete',
                type: 'complete',
                title: "\u52D5\u753B\u5B8C\u4E86 ".concat(tempCompletes.length, "\u4EF6"),
            });
        }
        return {
            date: date,
            events: events,
            isToday: isToday,
            hasMovie: tempHistories.length > 0,
            hasPractice: tempPractices.length > 0,
            hasComment: tempComments.length > 0,
            hasComplete: tempCompletes.length > 0,
            isCurrentMonth: beginningOfMonth.isSame(date, 'month'),
        };
    });
    var selectedDayData = useMemo(function () {
        if (!selectedDay) {
            return null;
        }
        var day = days.find(function (d) { return d.date.isSame(selectedDay, 'day'); });
        return day !== null && day !== void 0 ? day : null;
    }, [days, selectedDay]);
    var goPreviousMonth = useCallback(function () {
        var previousMonth = beginningOfMonth.subtract(1, 'month');
        Turbolinks.visit("/activities?year=".concat(previousMonth.year(), "&month=").concat(previousMonth.month() + 1));
    }, [beginningOfMonth]);
    var goNextMonth = useCallback(function () {
        var nextMonth = beginningOfMonth.add(1, 'month');
        Turbolinks.visit("/activities?year=".concat(nextMonth.year(), "&month=").concat(nextMonth.month() + 1));
    }, [beginningOfMonth]);
    var goCurrentMonth = useCallback(function () {
        Turbolinks.visit("/activities?year=".concat(today.year(), "&month=").concat(today.month() + 1));
    }, [today]);
    var handleDateClick = useCallback(function (date) {
        setSelectedDay(date);
    }, []);
    return {
        days: days,
        beginningOfMonth: beginningOfMonth,
        startDate: startDate,
        endDate: endDate,
        selectedDayData: selectedDayData,
        goPreviousMonth: goPreviousMonth,
        goNextMonth: goNextMonth,
        goCurrentMonth: goCurrentMonth,
        handleDateClick: handleDateClick,
    };
};
export default useHook;
