var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { zodResolver } from '@hookform/resolvers/zod';
import { isEqual } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { dayjs } from '@/utils/dayjs';
import { graphQlClient } from '@/utils/graphql';
import { schema } from './form-schema';
import { propsSchema } from './props';
var useHook = function (props) {
    var practice = propsSchema.parse(props).practice;
    var initialValues = {
        date: dayjs().format('YYYY-MM-DD'),
        place: practice.placeName,
        comment: practice.comment,
    };
    var _a = useForm({
        resolver: zodResolver(schema),
        defaultValues: initialValues,
    }), watch = _a.watch, control = _a.control, handleSubmit = _a.handleSubmit, isSubmitting = _a.formState.isSubmitting;
    var currentValues = watch();
    var canSubmit = useMemo(function () { return !isEqual(initialValues, currentValues); }, [initialValues, currentValues]);
    var submitButtonState = useMemo(function () {
        if (isSubmitting) {
            return 'submitting';
        }
        if (!canSubmit) {
            return 'disabled';
        }
        return 'enabled';
    }, [isSubmitting, canSubmit]);
    var submit = handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, graphQlClient.authPremiumPracticesEditUpdatePractice({
                        input: {
                            id: practice.id,
                            date: data.date,
                            placeName: data.place,
                            comment: data.comment,
                        },
                    })];
                case 1:
                    _a.sent();
                    Turbolinks.visit("/practices/".concat(practice.id, "?updated=1"));
                    return [2 /*return*/];
            }
        });
    }); });
    var deletePractice = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var confirm;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    confirm = window.confirm('本当に削除しますか？');
                    if (!confirm) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, graphQlClient.authPremiumPracticesDeletePractice({
                            input: {
                                id: practice.id,
                            },
                        })];
                case 1:
                    _a.sent();
                    Turbolinks.visit('/activities');
                    return [2 /*return*/];
            }
        });
    }); }, [practice.id]);
    return {
        submit: submit,
        control: control,
        deletePractice: deletePractice,
        submitButtonState: submitButtonState,
    };
};
export default useHook;
