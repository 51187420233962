import { articleSchema } from '@main/app/features/article/article.entity';
import { z } from 'zod';
export var propsSchema = z.object({
    practice: z.object({
        id: z.string(),
        date: z.string(),
        placeName: z.string().nullable(),
        comment: z.string().nullable(),
        feedbackCreatedAt: z.string().nullable(),
        feedbackComment: z.string().nullable(),
        feedbackKeywords: z.array(z.string()).nullable(),
    }),
    completedArticleIds: z.array(z.number()),
    recommendedArticles: z.array(articleSchema),
});
